@use 'base';
@use 'partials';

@media (min-width: 2200px) {
  html {
    font-size: 20px;
  }
  .info {
    .img {
      width: 40%;
      top: -11%;
    }
  }
  .advantages {
    padding-bottom: 20%;
    .blob-1,
    .blob-2,
    .blob-3 {
      left: 10%;
      transform: scale(1.15);
    }
    .blob-1 {
      top: 7%;
    }
    .blob-2 {
      top: 35%;
    }
    .blob-3 {
      bottom: 3%;
    }
  }

  .differences {
    > div > div.description.text-1 > h3 {
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .wrapper {
      .subtitle {
        margin-top: 0.5rem;
      }
      .text-2 {
        margin-top: 0;
        margin-left: 0;
      }
      .text-3 .subtitle {
        margin-bottom: 2rem;
      }
      .img-1 {
        right: 9%;
      }
      .img-2 {
        bottom: 10%;
        right: 4%;
      }
      .img-3 {
        bottom: 18%;
      }
    }
  }
  .contact {
    .title {
      margin-top: 10%;
    }
  }
}

@media (max-width: 1600px) {
  .header {
    .description {
      padding-left: 7%;
    }
  }

  .process {
    .img {
      width: 80%;
    }
    .circle {
      right: -30%;
    }
  }

  .differences {
    .wrapper {
      width: 80vw;
      .description {
        margin: 0;
        padding: 1rem;
      }
    }
  }
}

@media (max-width: 1500px) {
  .header {
    .description {
      padding-left: 5%;
    }
  }

  .info {
    width: 75vw;
  }

  .advantages {
    .circle-3 {
      bottom: -18%;
    }
  }

  .differences {
    .wrapper {
      width: 85vw;
      .description {
        margin: 0;
        padding: 1rem;
      }
    }
  }
}

@media (max-width: 1400px) {
  .header {
    .description {
      padding-left: 3%;
    }
  }

  .info {
    width: 84vw;
    .img {
      margin-top: 1%;
    }
  }
  .process {
    .circle {
      right: -30%;
    }
  }

  .advantages {
    .circle-1 {
      top: 2%;
      right: -18%;
    }

    .circle-3 {
      bottom: -12%;
    }
  }

  .differences {
    .wrapper {
      width: 89vw;
      .description {
        margin: 0;
        padding: 1rem;
        .subtitle {
          margin-bottom: 2rem;
        }
      }
      .text-2 {
        position: relative;
        width: 60%;
      }
      .img-2 {
        right: 4%;
      }
      .img-3 {
        right: 27%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .info {
    width: 85vw;
    .img {
      margin-top: 3%;
      margin-left: 5%;
    }
    .description {
      .title {
        margin-top: 0;
      }
    }
  }

  .process {
    .img {
      width: 90%;
    }
  }

  .advantages {
    padding-bottom: 10%;
    .list {
      width: 80vw;
      grid-template-rows: repeat(7, 1fr);
    }
    .blob-2 {
      top: 25%;
    }
    .blob-3 {
      display: none;
    }
  }

  .differences {
    .wrapper {
      width: 94vw;
      .description {
        padding: 0rem;
        .subtitle {
          margin-top: 1rem;
        }
        .text {
          font-size: 1.1rem;
        }
      }
      .img-1 {
        right: 22%;
      }
      .img-2 {
        left: -20%;
      }

      .text-2 {
        position: relative;
        width: 58%;
        right: 18%;
      }
    }
  }

  .contact {
    .form {
      width: 85vw;
      input[type="submit"] {
        width: 25%;
      }
    }
  }
}
@media (max-width: 1050px) {
  .advantages {
    padding-bottom: 8%;
  }
  .differences {
    .wrapper {
      width: 90vw;
      display: flex;
      flex-direction: column;
      position: static;
      .description {
        .subtitle {
          margin-bottom: 2rem;
        }
      }

      .description,
      .image {
        position: static;
        height: auto;
        margin: 1rem auto;
        width: 95%;
      }

      .text-2 {
        padding-right: 0;
      }
    }
  }
}

@media (max-width: 950px) {
  .header {
    background-size: contain;
    .description {
      padding-top: 15%;
    }

    &::before {
      top: 20%;
    }
    &::after {
      top: 10%;
    }
  }

  .info {
    width: 95vw;
  }

  .process {
    .img {
      width: 100%;
    }
  }

  .advantages {
    .title {
      font-size: 3rem;
      z-index: 3;
    }
    .list {
      gap: 1% 10%;
      .element {
        &.r4c2 {
          grid-row: 4 / 6;
          grid-column: 2 / 3;
        }

        &.r5c1 {
          grid-row: 5 / 7;
          grid-column: 1 / 2;
        }
      }
    }
    .blob-2 {
      top: 31%;
    }
  }

  .differences {
    .title {
      font-size: 3rem;
    }
  }

  .contact {
    margin: 0 auto;
    .title {
      margin-top: 0;
      font-size: 3rem;
    }
    .form {
      width: 90vw;
      input,
      textarea {
        width: 60%;
      }
    }
  }

  footer {
    .links {
      li {
        font-size: 0.825rem;
        padding: 0.5rem 0.25rem;
        &:not(:last-child)::after {
          padding-left: 0.5rem;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .header {
    padding-bottom: 15%;
    &::before {
      top: 10%;
    }
    &::after {
      top: 4%;
    }

    .description {
      padding-top: 15%;
      .title {
        &.small {
          margin: 0;
          font-size: 1.125rem;
        }
        &.big {
          font-size: 2.5rem;
        }
        &.italic {
          margin-top: 1.5rem;
          font-size: 2rem;
        }
      }
    }
  }

  .info {
    width: 90vw;
    .img {
      display: none;
    }

    .description {
      grid-column: 1 / 3;
      margin: 0 auto;
      .title {
        margin-top: 1rem;
        margin-bottom: 2.5rem;
      }
      p:nth-child(2) {
        margin-bottom: 0;
      }
    }
  }

  .process {
    .circle {
      top: 0;
    }
  }

  .advantages {
    margin-top: 0;
    padding-bottom: 34%;
    .list {
      grid-template-rows: 0.5fr 0.5fr 0.5fr 1fr;
      gap: 0 10%;
      padding-top: 8rem;
      .element {
        .icon {
          width: 40%;
        }
      }
    }

    .blob-1 {
      top: -1%;
      left: -15%;
    }

    .blob-2 {
      top: 34%;
      left: -20%;
    }

    .circle-1 {
      top: 1%;
      right: -18%;
    }

    .circle-2 {
      @include partials.circle(16vw);
      left: -8%;
    }

    .circle-3 {
      @include partials.circle(12vw);
      bottom: 10%;
      right: 16%;
    }
  }

  .differences {
    margin-top: 0;
    .title {
      margin-top: 0;
    }
  }

  .contact {
    .description {
      padding: 1rem;
    }
    .blob {
      bottom: 0%;
      right: -39%;
      top: 38%;
    }
    .form {
      input,
      textarea {
        width: 80%;
      }
    }
  }

  footer {
    .links {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      li {
        &:not(:last-child)::after {
          content: "";
        }
      }
    }
  }
}
