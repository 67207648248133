@use "base";

@mixin title($position: center) {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 4rem;
  text-align: $position;
}

@mixin circle($size: 46vw, $color: base.$color-light-blue) {
  background-color: $color;
  position: absolute;
  width: $size;
  height: $size;
  border-radius: 50%;
  opacity: 0.25;
}

%subtitle {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1px;
}

%block-img {
  width: 100%;
  margin: 0 auto;
  display: block;
}

%img-shadow {
  box-shadow: 5.5px 9.5px 35px 0 rgba(53, 67, 78, 0.37);
}

%form-input {
  font-family: base.$font-primary;
  color: #616a71;
  font-size: 1.25rem;
  border: none;
  width: 43%;
  padding: 1rem 1.5rem;
}

%blob-base {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
