$color-brown: #d2b397;
$color-blue: #809ab0;
$color-light-blue: rgb(171, 206, 221);
$color-white: #ffffff;
$color-black: #454c54;
$color-grey: #a2a6a9;

$font-primary: "Lato", sans-serif;
$font-secondary: "Noto Serif", serif;

html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: $font-primary;
  color: $color-black;
  margin: 0;
  padding: 0;
  margin: 0 auto;
}

.contact,
.differences,
.advantages,
.process {
  overflow: hidden;
}
