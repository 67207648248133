@use 'base';
@use "partials";

/*------------------------------------*\
    #HEADER
\*------------------------------------*/

.header {
  color: base.$color-white;
  position: relative;
  background-image: url("../img/hero_100.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 12rem;

  &::before {
    content: "";
    background-image: url("../blobs/blob-header-blue.svg");
    background-repeat: no-repeat;
    @extend %blob-base;
    background-size: 60%;
    top: 30%;
    left: -2%;
  }

  &::after {
    content: "";
    background-image: url("../blobs/blob-header-brown.svg");
    background-repeat: no-repeat;
    background-size: 64%;
    @extend %blob-base;
    top: 19%;
    left: -11%;
  }

  .description {
    padding-top: 31%;
    padding-left: 14%;
    position: relative;
    z-index: 2;

    .title {
      @include partials.title(left);
      &.small {
        margin: 0;
        font-size: 1.75rem;
        letter-spacing: 2.25px;
      }
      &.big {
        margin: 0.6rem 0 0;
        letter-spacing: 3.5px;
        font-size: 3.6rem;
      }
      &.italic {
        margin-top: 2.5rem;
        font-size: 2.25rem;
        font-style: italic;
        text-transform: none;
        letter-spacing: -0.5px;
        font-weight: 400;
        font-family: base.$font-secondary;
      }
    }
  }
}

/*------------------------------------*\
    #INFO
\*------------------------------------*/

.info {
  margin: 0 auto;
  width: 64vw;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  .image {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .description {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .img {
    position: absolute;
    @extend %img-shadow;
    border-radius: 50%;
    width: 46%;
    margin-top: -5%;
    margin-left: 7%;
    z-index: 4;
  }

  .description {
    margin-top: 0;
    margin-left: 18%;
  }

  .title {
    @extend %subtitle;
    margin-top: 1rem;
    margin-bottom: 3.5rem;
  }

  .text {
    line-height: 2;
    margin-bottom: 2.25rem;
    font-size: 1.1rem;
  }
}

/*------------------------------------*\
    #PROCESS
\*------------------------------------*/

.process {
  margin: 5% auto 0;
  width: 100%;
  position: relative;

  .img {
    @extend %block-img;
    width: 70%;
  }

  .circle {
    @include partials.circle(46vw, base.$color-brown);
    right: -30%;
    top: 0%;
  }
}

/*------------------------------------*\
#ADVANTAGES
\*------------------------------------*/
.advantages {
  margin: 1rem auto 0;
  padding-bottom: 25%;
  position: relative;

  .blob {
    position: absolute;
  }

  .blob-1 {
    top: 3%;
    left: -8%;
    z-index: 1;
  }

  .blob-2 {
    top: 29%;
    left: 2%;
  }

  .blob-3 {
    bottom: 0%;
    left: -10%;
    z-index: 2;
  }

  .circle-1 {
    @include partials.circle(36vw);
    z-index: 2;
    top: 0;
    right: -12%;
  }

  .circle-2 {
    @include partials.circle(14vw);
    z-index: 2;
    top: 33.5%;
    left: 4%;
    opacity: 0.85;
  }

  .circle-3 {
    @include partials.circle(8vw);
    z-index: 3;
    bottom: -20%;
    right: 18%;
    opacity: 1;
  }

  .title {
    @include partials.title(center);
    position: relative;
    z-index: 3;
    margin: 0 auto;
    line-height: 1.1;
    letter-spacing: 2px;
  }

  .list {
    width: 60vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
    padding-top: 15rem;
    gap: 2% 10%;
    position: relative;
    z-index: 4;

    .element {
      margin: 0 auto;
      text-align: center;
      color: base.$color-white;
      z-index: 1;

      .icon {
        width: 30%;
      }

      .subtitle {
        @extend %subtitle;
        margin: 2rem auto;
        letter-spacing: 2px;
      }

      .description {
        font-size: 1.125rem;
        line-height: 2;
        margin: 0 auto;
      }

      &.r1c1 {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
      }

      &.r2c2 {
        grid-row: 2 / 4;
        grid-column: 2 / 3;
      }

      &.r3c1 {
        grid-row: 3 / 5;
        grid-column: 1 / 2;
      }

      &.r4c2 {
        grid-row: 4 / 6;
        grid-column: 2 / 3;
      }

      &.r5c1 {
        grid-row: 5 / 7;
        grid-column: 1 / 2;
      }
    }
  }
}

/*------------------------------------*\
    #DIFFERENCES
\*------------------------------------*/

.differences {
  margin: 0 auto;

  .title {
    position: relative;
    z-index: 2;
    @include partials.title;
    margin-bottom: 5rem;
  }

  .wrapper {
    width: 65vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 0.5fr;
    position: relative;

    .subtitle {
      @extend %subtitle;
      letter-spacing: 2px;
      margin-bottom: 3rem;
    }

    .image {
      @extend %img-shadow;
    }

    .text {
      line-height: 2;
      font-size: 1.175rem;
    }

    .text-1 {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      width: 78%;
    }

    .img-1 {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      position: relative;
      right: 15%;
      bottom: 7%;
    }

    .text-2 {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
      margin-top: 5%;
      margin-left: 3%;
      width: 75%;

      .subtitle {
        margin-bottom: 2rem;
      }
    }

    .img-2 {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
      position: relative;
      bottom: 15%;
      right: 8%;
      z-index: 2;
    }

    .text-3 {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
      width: 73%;
      position: relative;
      bottom: 5%;
    }

    .img-3 {
      grid-row: 3 / 4;
      grid-column: 2 / 3;
      position: relative;
      bottom: 30%;
      right: 20%;
      z-index: 1;
    }
  }
}

/*------------------------------------*\
    #CONTACT
\*------------------------------------*/

.contact {
  margin: 0 auto 0;
  background-image: url("../bg/footer-bg.png");
  background-repeat: no-repeat;
  background-size: 121%;
  background-position: 73% 0;
  color: base.$color-white;
  text-align: center;
  padding-top: 12rem;
  padding-bottom: 2rem;
  position: relative;
  z-index: 2;

  .blob {
    position: absolute;
    bottom: -64%;
    right: -25%;
  }

  .title {
    @include partials.title;
    margin-top: 9rem;
    margin-bottom: 2.25rem;
  }

  .hr {
    width: 12vw;
    height: 0.5rem;
    background-color: #a1c8d9;
    border: #a1c8d9;
    padding: 0;
    margin-bottom: 1.25rem;
    position: relative;
    z-index: 3;
  }

  .description {
    font-size: 1.125rem;
    z-index: 1;
    position: relative;
  }

  .description p {
    line-height: 1.75;
    margin-top: 0;
    &:first-child {
      margin-bottom: 1rem;
    }
  }

  .phone {
    @extend %subtitle;
    font-weight: 700;
    font-size: 2.25rem;
    letter-spacing: 1.5px;
    margin: 0 auto;
    margin-left: -4rem;

    img {
      position: relative;
      top: 0.325rem;
      height: 2rem;
      margin-right: 1.5rem;
    }
  }

  a {
    color: base.$color-white;
    text-decoration: none;
  }

  .form {
    width: 70vw;
    margin: 3rem auto;
    position: relative;
    z-index: 5;

    .field {
      margin: 1.25rem auto;
      &:last-child {
        margin-top: 4rem;
      }
    }

    input,
    textarea {
      @extend %form-input;
      transition: transform 0.5s ease;
      &:focus,
      &:active {
        transform: scale(1.02);
      }
    }

    textarea {
      padding-top: 1.75rem;
      padding-bottom: 1.75rem;
      margin-bottom: 0;
      resize: vertical;
    }

    input[type="submit"] {
      width: 14.5%;
      background-color: base.$color-brown;
      color: base.$color-white;
      font-weight: 700;
      padding: 0.5rem;
      border-radius: 5rem;
      font-size: 1.5rem;
      margin-top: 1rem;
      cursor: pointer;
      transition: opacity 0.5s ease;

      &:hover {
        opacity: 0.85;
      }

      &:active {
        opacity: 0.6;
      }
    }
  }
}

/*------------------------------------*\
    #FOOTER
\*------------------------------------*/

footer {
  background-color: base.$color-black;
  .links {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0.5rem;
      color: base.$color-grey;
      &:not(:last-child)::after {
        content: "|";
        padding-left: 1rem;
      }
    }

    a {
      color: base.$color-grey;
      transition: color 0.5s ease;

      &:hover {
        color: whitesmoke;
      }
      &:active {
        color: white;
      }
    }
  }
}
