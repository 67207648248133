@use 'base';
@use 'partials';

@media (max-width: 450px) {
  html {
    font-size: 14px;
  }
  .header {
    .description {
      padding-top: 2.5%;
      padding-left: 4%;
      color: base.$color-black;
      .title {
        &.small {
          font-size: 1rem;
        }
        &.italic {
          margin-top: 0.5rem;
          margin-bottom: 0;
          font-size: 1.5rem;
        }
      }
    }
  }
  .info {
    .description {
      .title {
        margin-top: 1rem;
      }
    }
  }

  .advantages {
    .title {
      font-size: 2.5rem;
    }
    .list {
      display: flex;
      flex-direction: column;
      .element {
        margin-bottom: 10%;
        .subtitle {
          font-size: 1.75rem;
        }
        .description {
          font-size: 1rem;
        }
      }
    }
    .blob-2 {
      top: 35%;
      left: -30%;
    }
  }

  .differences {
    .title {
      font-size: 2.5rem;
    }
    .wrapper {
      .subtitle {
        font-size: 1.75rem;
      }
      .description {
        .text {
          font-size: 1rem;
        }
      }
    }
  }

  .contact {
    .title {
      font-size: 2.5rem;
    }
    .description {
      font-size: 1rem;
      .phone {
        text-align: center;
        margin: 0 auto;
        img {
          display: none;
        }
      }
    }

    .form {
      width: 100vw;
      margin: 0 auto;
      input,
      textarea {
        width: 92%;
        // font-size: 1.125rem;
        padding: 0.75rem;
      }
      input[type="submit"] {
        margin-top: 0;
        width: 50%;
      }
    }
    .blob {
      top: 28%;
      right: -75%;
    }
  }
}
