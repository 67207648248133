.header::after {
  animation: moveLess 20s ease-in-out infinite;
}

.process {
  .circle {
    animation: moveMore 30s ease-in-out infinite;
  }
}

.advantages {
  .circle-1,
  .circle-2 {
    animation: moveMore 25s ease-in-out infinite;
  }
  .blob-2 {
    animation: moveLess 20s ease-in-out infinite;
  }
  .blob-3 {
    animation: moveLess 20s ease-in-out infinite;
  }
}

.contact {
  .blob {
    animation: moveLess 20s ease-in-out infinite;
  }
}

@media (max-width: 700px) {
  .advantages {
    .circle-1,
    .circle-2 {
      animation: moveMore 30s ease-in-out infinite;
    }
    .blob-2 {
      animation: moveLess 60s ease-in-out infinite;
    }
    .blob-3 {
      animation: moveLess 45s ease-in-out infinite;
    }
  }
  .contact {
    .blob {
      animation: moveLess 30s ease-out infinite;
    }
  }
}

@media (max-width: 450px) {
  .header::after {
    animation: none;
  }

  .process {
    .circle {
      animation: none;
    }
  }

  .advantages {
    .circle-1,
    .circle-2 {
      animation: none;
    }
    .blob-2 {
      animation: none;
    }
    .blob-3 {
      animation: none;
    }
  }

  .contact {
    .blob {
      animation: none;
    }
  }
}
@keyframes moveMore {
  0% {
    transform: scale(1) translate(0, 0);
  }
  38% {
    transform: scale(0.8, 0.9) translate(2vw, 1vh) rotate(4deg);
  }
  40% {
    transform: scale(0.8, 0.9) translate(2vw, 1vh) rotate(4deg);
  }
  78% {
    transform: scale(1.1) translate(0vw, 2vh) rotate(-4deg);
  }
  80% {
    transform: scale(1.1) translate(0vw, 2vh) rotate(-4deg);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

@keyframes moveLess {
  0% {
    transform: scale(1) translate(0, 0);
  }
  38% {
    transform: scale(0.95, 0.95) translate(1vw, 1vh) rotate(4deg);
  }
  40% {
    transform: scale(0.95, 0.95) translate(1vw, 1vh) rotate(4deg);
  }
  78% {
    transform: scale(1.05) translate(0.5vw, 0.5vh) rotate(-4deg);
  }
  80% {
    transform: scale(1.05) translate(0.5vw, 0.5vh) rotate(-4deg);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}
